<template>
  <div>
    <a-card title="库位平面图">
      <a-row :gutter="16">
        <a-col :span="24" style="width: 256px">
          <a-form-item label="仓库" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select v-model="selectedWarehouse" placeholder="仓库" style="width: 100%" @change="onChangeWarehouse">
              <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="24" style="width: 256px">
          <a-form-item label="库区" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select v-model="searchForm.area" placeholder="库区" style="width: 100%" @change="onChangeArea">
              <a-select-option v-for="item in areaItems" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="24" style="width: 256px">
          <a-form-item label="货架或地面区域号" :label-col="{ span: 14 }" :wrapper-col="{ span: 10 }">
            <a-select
              v-model="searchForm.row_number"
              placeholder="货架或地面区域号"
              style="width: 100%"
              @change="search"
            >
              <a-select-option v-for="number in rowItems" :key="number" :value="number">
                {{ number }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="24" style="width: 256px">
          <a-form-item>
            <a-input v-model="searchValue" placeholder="产品编号, 名称" allowClear />
          </a-form-item>
        </a-col>

        <a-col :span="24" style="width: 100px">
          <a-form-item>
            <a-button type="primary" @click="search">查询</a-button>
          </a-form-item>
        </a-col>
      </a-row>

      <a-spin :spinning="queryLoading">
        <div style="height: 540px; overflow: auto; background-color: #efefef">
          <a-row v-for="layer in maxLayer" :key="layer" :gutter="[10, 10]" :style="{ width: `${90 * maxColumn}px` }">
            <a-col v-for="column in maxColumn" :key="`${layer}-${column}`" :span="24" :style="{ width: `90px` }">
              <location-grid :item="layoutData[`${layer}-${column}`] || {}" :searchValue="searchValue" />
            </a-col>
          </a-row>
        </div>
      </a-spin>

      <div style="margin-top: 16px">
        <a-space :size="24">
          <a-badge color="#52c41a" text="查询产品" />
          <a-badge color="#108ee9" text="有货库位" />
          <a-badge color="#faad14" text="无货库位" />
          <a-badge color="#d9d9d9" text="无库位" />
        </a-space>
      </div>
    </a-card>
  </div>
</template>

<script>
import { warehouseOption, reservoirAreaOption } from "@/api/option";
import { planDatas } from "@/api/data";

export default {
  components: {
    LocationGrid: () => import("./LocationGrid"),
  },
  data() {
    return {
      searchForm: {
        area: undefined,
        row_number: undefined,
      },
      searchValue: "",

      selectedWarehouse: undefined,
      warehouseItems: [],
      areaItems: [],
      rowItems: [],
      locationItems: [],
      maxLayer: 0,
      maxColumn: 0,
      layoutData: {},

      queryLoading: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    search() {
      if (!this.searchForm.area) {
        this.$message.warning("请选择库区");
        return;
      }

      if (!this.searchForm.row_number) {
        this.$message.warning("请选择货架或地面区域号");
        return;
      }

      this.queryLoading = true;
      let maxLayer = 0;
      let maxColumn = 0;
      planDatas(this.searchForm)
        .then((data) => {
          this.locationItems = data.results;
          const layoutData = {};

          for (const item of this.locationItems) {
            if (item.layer_number > maxLayer) {
              maxLayer = item.layer_number;
            }

            if (item.column_number > maxColumn) {
              maxColumn = item.column_number;
            }

            layoutData[`${item.layer_number}-${item.column_number}`] = item;
          }

          this.layoutData = layoutData;
          this.maxLayer = maxLayer;
          this.maxColumn = maxColumn;
        })
        .finally(() => (this.queryLoading = false));
    },
    onChangeWarehouse(value) {
      this.searchForm = { area: undefined, row_number: undefined };

      if (value) {
        reservoirAreaOption({ warehouse: value, page_size: 999999 }).then((data) => {
          this.areaItems = data.results;
        });
      }
    },
    onChangeArea(value) {
      this.searchForm.row_number = undefined;

      this.rowItems = [];
      let index = this.areaItems.findIndex((item) => item.id === value);
      if (index !== -1) {
        this.rowItems = this.areaItems[index].row_list;
      }
    },
  },
  mounted() {
    warehouseOption({ page_size: 999999 }).then((data) => {
      this.warehouseItems = data.results;
      this.selectedWarehouse = this.currentWarehouse;

      if (!this.selectedWarehouse && this.warehouseItems.length > 0) {
        this.selectedWarehouse = this.warehouseItems[0].id;
      }

      this.onChangeWarehouse(this.selectedWarehouse);
    });
  },
};
</script>

<style scoped></style>
